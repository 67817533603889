import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const WalletIcon1: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 26 26" {...props}>
      <path d="M22.3623 13.3189H19.5962C18.8626 13.3189 18.1591 13.6103 17.6403 14.129C17.1216 14.6478 16.8302 15.3513 16.8302 16.0849C16.8302 16.8185 17.1216 17.5221 17.6403 18.0408C18.1591 18.5595 18.8626 18.8509 19.5962 18.8509H22.3623V21.617C22.3623 21.9838 22.2165 22.3356 21.9572 22.5949C21.6978 22.8543 21.346 23 20.9792 23H4.38302C4.01622 23 3.66444 22.8543 3.40508 22.5949C3.14571 22.3356 3 21.9838 3 21.617V10.5528C3 10.3712 3.03577 10.1914 3.10528 10.0236C3.17478 9.85578 3.27665 9.70332 3.40508 9.5749C3.5335 9.44647 3.68596 9.3446 3.85376 9.2751C4.02156 9.20559 4.2014 9.16982 4.38302 9.16982H20.9792C21.1609 9.16982 21.3407 9.20559 21.5085 9.2751C21.6763 9.3446 21.8288 9.44647 21.9572 9.5749C22.0856 9.70332 22.1875 9.85578 22.257 10.0236C22.3265 10.1914 22.3623 10.3712 22.3623 10.5528V13.3189ZM17.5217 4.38319V7.7868H5.07453L15.5772 3.11912C15.7877 3.02556 16.0184 2.98611 16.2481 3.00433C16.4778 3.02256 16.6993 3.09789 16.8925 3.22347C17.0856 3.34906 17.2444 3.52093 17.3543 3.72346C17.4642 3.92599 17.5217 4.15277 17.5217 4.38319ZM19.5962 14.7019H22.3623C22.5439 14.7019 22.7238 14.7376 22.8916 14.8071C23.0594 14.8766 23.2119 14.9785 23.3403 15.1069C23.4688 15.2353 23.5707 15.3878 23.6402 15.5556C23.7097 15.7234 23.7455 15.9033 23.7455 16.0849C23.7455 16.2665 23.7097 16.4464 23.6402 16.6142C23.5707 16.782 23.4688 16.9345 23.3403 17.0629C23.2119 17.1914 23.0594 17.2932 22.8916 17.3627C22.7238 17.4322 22.5439 17.468 22.3623 17.4679H19.5962C19.4146 17.468 19.2347 17.4322 19.0669 17.3627C18.8991 17.2932 18.7466 17.1914 18.6181 17.0629C18.4897 16.9345 18.3878 16.782 18.3183 16.6142C18.2488 16.4464 18.213 16.2665 18.213 16.0849C18.213 15.9033 18.2488 15.7234 18.3183 15.5556C18.3878 15.3878 18.4897 15.2353 18.6181 15.1069C18.7466 14.9785 18.8991 14.8766 19.0669 14.8071C19.2347 14.7376 19.4146 14.7019 19.5962 14.7019Z" />
    </Svg>
  );
};

export default WalletIcon1;
