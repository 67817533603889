import { ChainId } from '@pancakeswap/chains'
import { chainlinkOracleCAKE } from '@pancakeswap/prediction'
import { bscTokens } from '@pancakeswap/tokens'
import { BIG_ZERO } from '@pancakeswap/utils/bigNumber'
import BigNumber from 'bignumber.js'
import { chainlinkOracleABI } from 'config/abi/chainlinkOracle'
import { publicClient } from 'utils/wagmi'
import { formatUnits } from 'viem'
import { useCurrencyUsdPrice } from './useCurrencyUsdPrice'

// for migration to bignumber.js to avoid breaking changes
export const useCakePrice = ({ enabled = true } = {}) => {
  // const { data } = useQuery<BigNumber, Error>({
  //   queryKey: ['cakePrice'],
  //   queryFn: async () => new BigNumber(await getCakePriceFromOracle()),
  //   staleTime: FAST_INTERVAL,
  //   refetchInterval: FAST_INTERVAL,
  //   enabled,
  // })
  // return data ?? BIG_ZERO

  const { data } = useCurrencyUsdPrice(bscTokens.lybk)

  return data ? new BigNumber(data) : BIG_ZERO
}

export const getCakePriceFromOracle = async () => {
  const data = await publicClient({ chainId: ChainId.BSC }).readContract({
    abi: chainlinkOracleABI,
    address: chainlinkOracleCAKE[ChainId.BSC],
    functionName: 'latestAnswer',
  })

  return formatUnits(data, 8)
}
