import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const SwapIcon1: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 27 26" {...props}>
      <path
        d="M11.7823 13.7321L14.3591 16.305L16.8475 13.8203C16.9936 13.6744 17.1958 13.5836 17.419 13.5836H22.9825C22.9935 13.3905 23 13.1959 23 12.9995C23 7.47676 18.5169 3 12.9871 3C7.69872 3 3.36826 7.09483 3 12.2828H7.75398L10.5783 9.46372C10.8938 9.1486 11.4053 9.1486 11.7209 9.46372L14.8691 12.6074C15.165 12.9258 15.1556 13.421 14.848 13.7281C14.5405 14.0352 14.0446 14.0443 13.7258 13.7492L11.1491 11.1762L8.66064 13.6609C8.58562 13.7359 8.49653 13.7954 8.39848 13.8361C8.30042 13.8767 8.19531 13.8976 8.08916 13.8976H3.016C3.47042 18.9996 7.76161 23 12.9882 23C17.7618 23 21.7549 19.6632 22.7579 15.1984H17.7545L14.9317 18.0175C14.6157 18.3323 14.1042 18.3323 13.7883 18.0175L10.6401 14.8739C10.3245 14.5588 10.3242 14.0479 10.6397 13.7328C10.9553 13.4173 11.4668 13.417 11.7823 13.7321Z"
        fill={props.fill}
      />
    </Svg>
  );
};

export default SwapIcon1;
