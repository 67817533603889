import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | AppleSwap',
  defaultTitle: 'AppleSwap',
  description: 'Trade, earn, and own crypto on the all-in-one multichain DEX',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@AppleSwap',
    site: '@AppleSwap',
  },
  openGraph: {
    title: "🥞 AppleSwap - Everyone's Favorite DEX",
    description: 'Trade, earn, and own crypto on the all-in-one multichain DEX',
    // images: [{ url: 'https://assets.LYBK.finance/web/og/v2/hero.jpg' }],
  },
}
