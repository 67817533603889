import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 26 26" {...props}>
      <path d="M12.2974 10.1998L15.7979 7.56529C16.1978 7.83263 16.6639 7.99943 17.1986 7.99943C18.5649 7.99943 19.6983 6.8661 19.6983 5.49971C19.664 4.13333 18.5307 3 17.1643 3C15.7979 3 14.6646 4.13333 14.6646 5.49971C14.6646 5.76705 14.7308 6.00011 14.7971 6.23318L11.2966 8.86542C11.6645 9.26528 11.9981 9.69942 12.2974 10.1998ZM20.498 10.5654C19.4309 10.5654 18.5307 11.2326 18.1651 12.1649H12.9303C12.9646 12.4322 12.9966 12.6972 12.9966 12.9989C12.9966 13.2982 12.9623 13.5655 12.9303 13.8329H18.1308C18.4644 14.8337 19.3967 15.5671 20.498 15.5671C21.8644 15.5671 22.9977 14.4338 22.9977 13.0674C22.9977 11.6667 21.8644 10.5654 20.498 10.5654ZM7.16314 8.83343C4.91249 8.83343 3.07769 10.6202 3 12.8526V13.1497C3.07769 15.382 4.91477 17.1689 7.16314 17.1689C9.46407 17.1689 11.3309 15.3021 11.3309 13.0011C11.3309 10.7002 9.46407 8.83343 7.16314 8.83343ZM17.1643 23C18.5307 23 19.664 21.8667 19.664 20.5003C19.664 19.1339 18.5307 18.0006 17.1643 18.0006C16.6639 18.0006 16.1635 18.1674 15.7636 18.4347L12.2631 15.8002C11.9958 16.3006 11.6622 16.7324 11.2623 17.1346L14.7628 19.7691C14.7285 20.0022 14.6623 20.2352 14.6623 20.5026C14.6646 21.8667 15.7979 23 17.1643 23Z" />
    </Svg>
  );
};

export default Icon;
